<template>
  <div v-if="!modal" class="flex h-12 items-center justify-between border-b border-gray-800 px-3">
    <div class="text-sm font-medium text-gray-100">{{ webhook?.id ? `Edit` : 'Create' }} Destination Settings</div>
  </div>
  <div style="height: calc(100% - 65px)">
    <div v-if="!webhook?.id" class="px-4">
      <BaseRadio v-if="!modal" :options="radioChannels" v-model="selectedRadioChannel" />
      <BaseSelect
        v-else
        v-model="selectedChannel"
        button-width-class="w-full"
        dropdown-width-class="w-full"
        :options="channels"
      >
        <div class="text-xs font-medium text-gray-300">Select Destination</div>
      </BaseSelect>
    </div>
    <div :class="[!modal ? 'overflow-y-auto pb-2' : '', 'h-full']">
      <AlertsDeliveryDiscordInstructions v-if="selectedChannel === 2" />
      <AlertsDeliveryTelegramInstructions v-else-if="selectedChannel === 3" />
      <AlertsDeliverySlackInstructions v-else-if="selectedChannel === 4" />
      <AlertsDeliveryMobileTab v-else-if="selectedChannel === 6" />
      <AlertsDeliveryForm :modal="modal" v-if="selectedChannel !== 6" v-model="currentWebhook" :edit="!!webhook?.id" />
    </div>
  </div>
</template>

<script setup>
import { ref, watch, inject, computed } from 'vue';
import AlertsDeliveryMobileTab from '@/components/alerts/delivery/AlertsDeliveryMobileTab.vue';
import AlertsDeliveryForm from './AlertsDeliveryForm.vue';
import AlertsDeliveryDiscordInstructions from './AlertsDeliveryDiscordInstructions.vue';
import AlertsDeliveryTelegramInstructions from './AlertsDeliveryTelegramInstructions.vue';
import AlertsDeliverySlackInstructions from './AlertsDeliverySlackInstructions.vue';
import { titleize } from '@/composeables/filters';

const props = defineProps({
  webhook: { type: Object, default: null },
  modal: { type: Boolean, default: false }
});

//CURRENT WEBHOOK
const currentWebhook = ref({
  name: '',
  link: '',
  status: '',
  channel: 'email',
  id: 0,
  mode: 'news'
});
watch(
  () => currentWebhook.value,
  () => {
    if (currentWebhook.value) {
      currentWebhook.value.status = 'verified';
      selectedChannel.value = titleize(currentWebhook.value.channel);
    }
  }
);

watch(
  () => props.webhook,
  val => {
    if (val) currentWebhook.value = { ...currentWebhook, ...val };
  },
  { deep: true, immediate: true }
);

//CHANNELS SELECT
const channels = [
  { id: 1, label: 'Email' },
  { id: 2, label: 'Discord' },
  { id: 3, label: 'Telegram' },
  { id: 4, label: 'Slack' },
  { id: 5, label: 'Webhook' },
  { id: 6, label: 'Mobile' }
];
const selectedChannel = ref(1);
watch(
  () => selectedChannel.value,
  () => {
    if (props.modal)
      currentWebhook.value.channel = channels.find(x => x.id === selectedChannel.value).label.toLowerCase();
  }
);

// CHANNELS RADIO
const radioChannels = computed(() => {
  return channels.map(x => x.label);
});
const selectedRadioChannel = ref('Email');
watch(
  () => selectedRadioChannel.value,
  () => {
    let channel = channels.find(x => x.label === selectedRadioChannel.value);
    currentWebhook.value.channel = channel.label.toLowerCase();
    selectedChannel.value = channel.id;
  }
);

//MOBILE SETUP
const forceMobileSetup = inject('force-mobile-setup');
watch(forceMobileSetup, () => {
  if (forceMobileSetup.value) selectedChannel.value = 6;
});
</script>
